var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("group")]),_c('span',[_c('span',[_vm._v("Utilisateurs ")]),_c('b',{staticClass:"text-subtitle-1"},[_vm._v(" ("+_vm._s(_vm.searchInfos.overallTotal)+") ")])])],1),_c('v-divider'),_c('v-card-text',{attrs:{"id":"table-users"}},[_c('base-data-table',{staticClass:"table-clickable",attrs:{"headers":_vm.headers,"fixedHeaders":true,"items":_vm.users,"containerId":"#table-users","clickOnRow":true,"options":_vm.pagination,"serverItemsLength":_vm.searchInfos.filteredTotal,"loading":_vm.loading,"itemsPages":[50, 100, 200],"totalPages":_vm.searchInfos.totalPages,"itemClickRouterTarget":"user-details"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.lastLoginDate",fn:function(ref){
var item = ref.item;
return [(item.lastLoginDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeToString(item.lastLoginDate, true))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""}},[(item.emailConfirmed)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',[_vm._v("close")])],1)]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',[_vm._v("close")])]}},{key:"item.rights",fn:function(ref){
var item = ref.item;
return [(item.rights)?_c('span',[_vm._v(" "+_vm._s(item.rights.join(", ")))]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }