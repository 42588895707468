var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("stars")]),_c('span',[_c('span',[_vm._v("Divinités ")]),_c('b',{staticClass:"text-subtitle-1"},[_vm._v(" ("+_vm._s(_vm.searchInfos.overallTotal)+") ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.openDialogAsCreate}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-divider'),_c('v-card-text',{attrs:{"id":"table-gods"}},[_c('base-data-table',{staticClass:"table-clickable",attrs:{"headers":_vm.headers,"fixedHeaders":true,"items":_vm.gods,"containerId":"#table-gods","clickOnRow":true,"options":_vm.pagination,"serverItemsLength":_vm.searchInfos.filteredTotal,"loading":_vm.loading,"itemsPages":[10, 25, 50, 100, 200],"totalPages":_vm.searchInfos.totalPages,"itemClickRouterTarget":"dialog:god","itemClass":"align-center"},on:{"update:options":function($event){_vm.pagination=$event},"open-dialog":function($event){return _vm.openDialogAsUpdate($event)}},scopedSlots:_vm._u([{key:"item.modificationDate",fn:function(ref){
var item = ref.item;
return [(item.modificationDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeToString(item.modificationDate, false))+" par "+_vm._s(item.modifiedBy)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.baseCooldown",fn:function(ref){
var item = ref.item;
return [(item.baseCooldown)?_c('span',[_vm._v(" "+_vm._s(_vm.toUserFriendlyTime(item.baseCooldown))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.nameVF",fn:function(ref){
var item = ref.item;
return [(item.nameVO)?_c('v-icon',{attrs:{"left":""}},[_vm._v("closed_caption")]):_c('v-icon',{attrs:{"color":"red darken-2","left":""}},[_vm._v("closed_caption_disabled")]),_c('span',[_vm._v(_vm._s(item.nameVF))])]}},{key:"item.titleVF",fn:function(ref){
var item = ref.item;
return [(item.titleVO)?_c('v-icon',{attrs:{"left":""}},[_vm._v("closed_caption")]):_c('v-icon',{attrs:{"color":"red darken-2","left":""}},[_vm._v("closed_caption_disabled")]),_c('span',[_vm._v(_vm._s(item.titleVF))])]}},{key:"item.descriptionVF",fn:function(ref){
var item = ref.item;
return [(item.descriptionVF)?_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("check")]):_c('v-icon',[_vm._v("close")])]}},{key:"item.descriptionVO",fn:function(ref){
var item = ref.item;
return [(item.descriptionVO)?_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("check")]):_c('v-icon',[_vm._v("close")])]}}],null,true)})],1)],1),(_vm.godDialog)?_c('create-update-god-dialog',{attrs:{"open":_vm.godDialog,"updateMode":_vm.updateMode,"createMode":_vm.createMode,"god":_vm.editedGod},on:{"update:open":function($event){_vm.godDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }