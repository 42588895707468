var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("explore")]),_c('span',[_c('span',[_vm._v("Mondes")]),_c('b',{staticClass:"text-subtitle-1"},[_vm._v(" ("+_vm._s(_vm.searchInfos.overallTotal)+") ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.worldDialog = true}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-divider'),_c('v-card-text',{attrs:{"id":"table-worlds"}},[_c('base-data-table',{staticClass:"table-clickable",attrs:{"headers":_vm.headers,"fixedHeaders":true,"items":_vm.worlds,"containerId":"#table-worlds","clickOnRow":true,"options":_vm.pagination,"serverItemsLength":_vm.searchInfos.filteredTotal,"loading":_vm.loading,"itemsPages":[10, 25, 50, 100, 200],"totalPages":_vm.searchInfos.totalPages,"itemClickRouterTarget":"dialog:world","itemClass":"align-center"},on:{"update:options":function($event){_vm.pagination=$event},"open-dialog":function($event){return _vm.openDialogAsUpdate($event)}},scopedSlots:_vm._u([{key:"item.findableGods",fn:function(ref){
var item = ref.item;
return [(typeof item.findableGods == 'string' && item.findableGods)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" check ")]):_c('v-icon',[_vm._v("close")])]}},{key:"item.modificationDate",fn:function(ref){
var item = ref.item;
return [(item.modificationDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeToString(item.modificationDate, false))+" par "+_vm._s(item.modifiedBy)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.nameVF",fn:function(ref){
var item = ref.item;
return [(item.nameVO)?_c('v-icon',{attrs:{"left":""}},[_vm._v("closed_caption")]):_c('v-icon',{attrs:{"color":"red darken-2","left":""}},[_vm._v("closed_caption_disabled")]),_c('span',[_vm._v(_vm._s(item.nameVF))])]}},{key:"item.descriptionVF",fn:function(ref){
var item = ref.item;
return [(item.descriptionVF)?_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("check")]):_c('v-icon',[_vm._v("close")])]}},{key:"item.descriptionVO",fn:function(ref){
var item = ref.item;
return [(item.descriptionVO)?_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("check")]):_c('v-icon',[_vm._v("close")])]}}],null,true)})],1)],1),(_vm.worldDialog)?_c('create-update-world-dialog',{attrs:{"open":_vm.worldDialog,"updateMode":_vm.updateMode,"createMode":_vm.createMode,"world":_vm.editedWorld},on:{"update:open":function($event){_vm.worldDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }