<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :item-class="itemClass"
    :fixed-header="fixedHeaders"
    :server-items-length="serverItemsLength"
    :loading="loading"
    @click:row="(item, event) => (clickOnRow ? openItem(item, event) : nothing())"
    :options.sync="optionsProxy"
    :items-per-page="nbItemsByPage"
    :height="tableHeight"
    :footer-props="{
      itemsPerPageOptions: itemsPages ? itemsPages : [5, 10, 25, 50, -1],
    }"
    :hide-default-footer="$vuetify.breakpoint.xs"
  >
    <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
      <slot :name="[`item.${header.value}`]" :item="item">
        {{ getVal(item, header.value) }}
      </slot>
    </template>
    <template #footer v-if="$vuetify.breakpoint.xs">
      <v-divider></v-divider>
      <v-pagination
        circle
        v-model="optionsProxy.page"
        :length="totalPages"
      ></v-pagination>
    </template>
  </v-data-table>
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
  props: {
    headers: Array,
    items: Array,
    fixedHeaders: Boolean,
    nbItemsByPage: Number,
    serverItemsLength: Number,
    loading: Boolean,
    options: {
      type: Object,
      required: true,
    },
    containerId: {
      type: String,
      required: true,
    },
    itemClickRouterTarget: String,
    clickOnRow: { type: Boolean, default: false },
    itemClass: String,
    itemsPages: Array,
    totalPages: Number,
  },
  setup(props, { emit, root }) {
    /* Variables */
    const tableHeight = ref(null);
    const ctrlKeyDown = ref(false);

    /* Computed */
    const optionsProxy = computed({
      get: function () {
        return props.options;
      },
      set: function (value) {
        emit("update:options", value);
      },
    });

    /* Méthodes */
    const computeTableHeight = function (containerElement) {
      const paginationFooterHeight = 65; // A utiliser si on a active la pagination
      const paddingCardContainer = 40;
      // On récupère la position basse du composant des filtres
      const positionContainer = containerElement.getBoundingClientRect().top;
      // Idem avec le bas de l'écran
      const screenHeight = window.innerHeight;
      // On assigne la hauteur de la table pour pouvoir bénéficier des headers fixes
      return (
        screenHeight - positionContainer - paginationFooterHeight - paddingCardContainer
      );
    };
    const openInNewTab = function (item) {
      const routeData = router.resolve({
        name: props.itemClickRouterTarget,
        params: { id: item._id },
      });
      window.open(routeData.href, "_blank");
    };
    const openItem = function (item, event) {
      console.log(event);
      if (props.itemClickRouterTarget.indexOf("dialog") > -1) {
        emit("open-dialog", item);
      } else {
        if (ctrlKeyDown.value) {
          openInNewTab(item);
        } else {
          router.push({
            name: props.itemClickRouterTarget,
            params: { id: item._id },
          });
        }
      }
    };
    const triggerCtrlDown = function (event) {
      if (event.ctrlKey) {
        ctrlKeyDown.value = true;
      }
    };
    const triggerCtrlUp = function (event) {
      if (event.ctrlKey) {
        ctrlKeyDown.value = false;
      }
    };
    const getVal = function (item, path) {
      return path.split(".").reduce((res, prop) => res[prop], item);
    };
    const nothing = function () {
      // DO NOTHING
    };

    /* Watchers */
    watch(
      () => props.options.page,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          const dataContainer = document.querySelector(
            `${props.containerId} .v-data-table__wrapper`
          );
          if (dataContainer) {
            dataContainer.scrollTo(0, 0);
          }
        }
      },
      { deep: true }
    );

    /* Cycle de vie */
    onMounted(() => {
      document.addEventListener("keydown", triggerCtrlDown);
      document.addEventListener("keyup", triggerCtrlUp);
      const containerElement =
        props.containerId.indexOf("#") > -1
          ? document.querySelector(props.containerId)
          : document.getElementById(props.containerId);
      window.addEventListener("resize", function () {
        if (containerElement != null) {
          tableHeight.value = computeTableHeight(containerElement);
        }
      });
      if (props.fixedHeaders && props.containerId && containerElement != null) {
        tableHeight.value = computeTableHeight(containerElement);
      }
    });
    onUnmounted(() => {
      document.removeEventListener("keydown", triggerCtrlDown);
      document.removeEventListener("keyup", triggerCtrlUp);
    });
    return {
      openItem,
      tableHeight,
      optionsProxy,
      getVal,
      nothing,
    };
  },
});
</script>
