<template>
	<div :id="graphId"></div>
</template>
<script>
import { defineComponent, onMounted, watch } from "@vue/composition-api";
import * as echarts from "echarts";
import chartsLocaleFR from "@/locales/chartsFR";

export default defineComponent({
	props: {
		graphId: String,
		options: Object,
		height: Number,
		width: Number,
		lazyLoad: {
			type: Boolean,
			default: true,
		},
		destroyBeforeRender: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		let myChart = null;

		onMounted(() => {
			mountChart();
		});

		const mountChart = function () {
			echarts.registerLocale("FR", chartsLocaleFR);
			myChart = echarts.init(document.getElementById(props.graphId), "default", {
				height: props.height ? `${props.height}px` : "300px",
				width: props.width ? `${props.width}px` : "auto",
				renderer: "canvas",
				locale: "FR",
			});
			myChart.showLoading();
			if (props.options != undefined) {
				myChart.setOption(props.options);
			}
			myChart.hideLoading();
			window.addEventListener("resize", function () {
				myChart.resize();
			});
		};

		watch(
			() => props.options,
			() => {
				if (props.lazyLoad) {
					setTimeout(function () {
						if (props.destroyBeforeRender) {
							myChart.dispose();
							mountChart();
						} else {
							myChart.setOption(props.options, true, true);
						}
					}, 10);
				} else {
					myChart.setOption(props.options, true, true);
				}
			},
			{ deep: true }
		);
		watch(
			() => props.height,
			(newValue, oldValue) => {
				if (newValue != oldValue) {
					myChart.resize({
						height: newValue,
					});
				}
			},
			{ deep: true }
		);

		return {};
	},
});
</script>
